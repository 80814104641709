import React from 'react';
import { useAppSelector } from '../store';
import { Navigate } from 'react-router-dom';
import { routes } from '../shared/routes';
import { selectAuthorizationStatus } from '../store/Authorization/Authorization.selectors';


export function withAuthRedirect<WCP>(WrapperComponent: React.ComponentType<WCP>): React.FC {

	const RedirectComponent: React.FC = (props) => {

		const isAuth = useAppSelector(selectAuthorizationStatus);

		if (!isAuth) return <Navigate to={routes.signIn} />;

		return <WrapperComponent {...props as any} />;

	};

	return RedirectComponent;
}




