// --- JS
import { setIsLoading, setAccessKeys, fetchAccessKeys } from './Developer.actions';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { DeveloperApi } from './Developer.services';
// --- TS
import { LoadingStatus } from '../../shared/status';
import { IAccessKey } from './types';


export function* fetchAccessKeysRequest(): Generator<any, void, IAccessKey[]> {
	try {
		yield put(setIsLoading(LoadingStatus.LOADING));
		const data = yield call(DeveloperApi.getAccessKeys);
		yield put(setAccessKeys(data));
		yield put(setIsLoading(LoadingStatus.LOADED));
	} catch (e) {
		yield put(setIsLoading(LoadingStatus.ERROR));
	}
}


export function* saga(): Generator {
	yield takeLatest(fetchAccessKeys, fetchAccessKeysRequest);
}