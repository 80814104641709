import { ThemeOptions } from '@mui/material';

const customTypography: Pick<ThemeOptions, 'typography'> = {
	typography: {
		fontFamily: 'Roboto',

		fontSize: 16,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightLight: 600,
		fontWeightBold: 700,

		overline: {
			fontSize: '0.625em',	// 10px
			lineHeight: '1em',  	// 16px
			fontWeight: 500,
		},

		caption: {
			fontSize: '0.75em',		// 12px
			lineHeight: '1em',		// 16px
		},

		button: {
			fontSize: '0.875em',	// 14px
			lineHeight: '1em',		// 16px
			fontWeight: 500,
		},

		body2: {
			fontSize: '0.875em',	// 14px
			lineHeight: '1.25em',	// 16px
		},

		body1: {
			fontSize: '1em',		// 16px
			lineHeight: '1.5em',	// 20px
		},

		subtitle2: {
			fontSize: '0.875em',	// 14px
			lineHeight: '1.5em',	// 24px
			fontWeight: 500,
		},

		subtitle1: {
			fontSize: '1em',		// 16px
			lineHeight: '1.5em',	// 24px
		},

		h6: {
			fontSize: '1.25em',		// 20px
			lineHeight: '1.5em',	// 24px
			fontWeight: 500,
		},

		h5: {
			fontSize: '1.5em',		// 24px
			lineHeight: '1.5em'		// 24px
		},

		h4: {
			fontSize: '1.75em', 	// 28px
			lineHeight: '2em',		// 32px
		},

		h3: {
			fontSize: '2em',		// 32px
			lineHeight: '2em'		// 32px
		},

		h2: {
			fontSize: '2.25em',		// 36px
			lineHeight: '2.25em',	// 36px
		}

	}
};

export const typography = customTypography.typography;