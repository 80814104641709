import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IAuthorizationInitialState } from './types';

const fetchLogout: CaseReducer<IAuthorizationInitialState, PayloadAction> = () => {};

const fetchCheckAuthorization: CaseReducer<IAuthorizationInitialState, PayloadAction> = () => {};

const setAuthorizationStatus: CaseReducer<IAuthorizationInitialState, PayloadAction<IAuthorizationInitialState['isAuth']>> = (_state, _action) => {
	_state.isAuth = _action.payload;
};

export default { fetchLogout, fetchCheckAuthorization,  setAuthorizationStatus };


