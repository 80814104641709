import { ThemeOptions } from "@mui/material";

const customPalette: Pick<ThemeOptions, 'palette'> = {
	palette: {
		text: {
			primary: '#121212',
			secondary: 'rgba(18, 18, 18, 0.6)',
		},
		primary: {
			main: '#9E9EF3',
			contrastText: '#000000',
		}
	}
};

export const palette = customPalette.palette;