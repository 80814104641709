import { instance } from '../../services';
import { ISignInForm } from '../../pages/SignInPage/types';
import { ISignUpForm } from '../../pages/SignUpPage/types';

export const AuthorizationApi = {
	async signIn(params: ISignInForm): Promise<ISignInResponse> {
		const { data } = await instance.post('public/authenticate', params);
		return data;
	},

	async signUp(params: ISignUpForm): Promise<void> {
		const { data } = await instance.post('public/register', params);
		return data;
	},
};

interface ISignInResponse {
	id_token: string;
}