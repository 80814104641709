import {  CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IDeveloperInitialState } from './types';

const fetchAccessKeys: CaseReducer<IDeveloperInitialState, PayloadAction> = () => {};

const setIsLoading: CaseReducer<IDeveloperInitialState, PayloadAction<IDeveloperInitialState['isLoading']>> = (_state, _action) => {
	_state.isLoading = _action.payload;
};

const setAccessKeys: CaseReducer<IDeveloperInitialState, PayloadAction<IDeveloperInitialState['accessKeys']>> = (_state, _action) => {
	_state.accessKeys = _action.payload;
};

export default { fetchAccessKeys, setIsLoading, setAccessKeys };