import React from 'react';
import Box from '@mui/material/Box';
import { RedocStandalone } from 'redoc';
// --- open api file
import apiFile from './api.json';

export const DocumentPage: React.FC = () => {
    return (
        <Box position='absolute' left={0} right={0}>
            {/* @ts-ignore */}
            <RedocStandalone specUrl={apiFile} />
        </Box>
    );
};