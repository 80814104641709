import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// --- JS
import { NavLink } from 'react-router-dom';
import { routes } from '../shared/routes';
import { useAppSelector } from '../store';
import { selectAuthorizationStatus } from '../store/Authorization/Authorization.selectors';

export const Header: React.FC = () => {

	const isAuth = useAppSelector(selectAuthorizationStatus);

	return (
		<Box 
			position='fixed' 
			left={0} 
			right={0} 
			zIndex={999}
			bgcolor='white'
			boxShadow='0 2px 2px -2px gray'>
			<Box
				height='50px'
				maxWidth='1240px'
				margin='0 auto'

				display='flex'
				alignItems='center'
				justifyContent='space-between'>

				<NavLink to={routes.welcome}>
					<Typography fontWeight='700' variant='h6' color='black'> ASEND DEV </Typography>
				</NavLink>

				<Box display='flex' gap='24px'>
					<NavLink to={isAuth ? routes.documentation : routes.signIn}>
						<Typography variant='subtitle1' fontWeight='500' color='black'> Documentation </Typography>
					</NavLink>

					<NavLink to={isAuth ? routes.developerPortal : routes.signIn}>
						<Typography variant='subtitle1' fontWeight='500' color='black'> Developer Portal </Typography>
					</NavLink>
				</Box>

			</Box>

		</Box>
	);
};