import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextFiled from '@mui/material/TextField';
import { AuthorizationWrapper } from '../../components';
// --- JS
import { routes } from '../../shared/routes';
import { isValidEmail } from '../../shared/regex';
import { useNavigate, NavLink } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { loggerError, LoggerType } from '../../shared/logger';
import { AuthorizationApi } from '../../store/Authorization/Authorization.services';
// --- TYPES
import { ISignUpForm } from './types';
import { LoadingStatus } from '../../shared/status';



export const SignUpPage: React.FC = () => {

	const navigate = useNavigate();

	const [error, setError] = React.useState<string>('');

	const [isLoading, setIsLoading] = React.useState(LoadingStatus.NEVER);

	const { register, handleSubmit, formState } = useForm<ISignUpForm>();

	const { errors } = formState;

	const onSignUpRequest: SubmitHandler<ISignUpForm> = async (form) => {
		try {
			setIsLoading(LoadingStatus.LOADING);
			const data = await AuthorizationApi.signUp(form);
			console.log(data);
			navigate(routes.signIn);
			setIsLoading(LoadingStatus.LOADED);
		} catch (e: any) {
			setIsLoading(LoadingStatus.ERROR);
			loggerError({ e, type: LoggerType.NETWORK });
			setError(e?.response?.data?.message || '');
		}
	};

	return (
		<AuthorizationWrapper>
			<form onSubmit={handleSubmit(onSignUpRequest)}>

				{isLoading === LoadingStatus.ERROR
					&& <Box mb='15px'>
						<Alert severity='error'>  {error || 'Unknown error'}  </Alert>
					</Box>}

				<Box display='flex' flexDirection='column' gap='20px'>
					<TextFiled
						fullWidth
						size='small'
						label='Email'
						{...register('email', {
							required: { value: true, message: 'Please enter your email' },
							pattern: { value: isValidEmail, message: 'Please use a valid email' },
						})}
						error={Boolean(errors.email)}
						helperText={errors.email?.message} />

					<Box display='flex' gap='15px'>
						<TextFiled
							fullWidth
							size='small'
							label='First Name'
							{...register('firstname', {
								required: { value: true, message: 'Please enter your first name' },
							})}
							error={Boolean(errors.firstname)}
							helperText={errors.firstname?.message} />

						<TextFiled
							fullWidth
							size='small'
							label='Last Name'
							{...register('lastname', {
								required: { value: true, message: 'Please enter your last name' }
							})}
							error={Boolean(errors.lastname)}
							helperText={errors.lastname?.message} />
					</Box>

					<TextFiled
						fullWidth
						size='small'
						type='password'
						label='Password'
						{...register('password', {
							required: { value: true, message: 'Please enter your password' }
						})}
						error={Boolean(errors.password)}
						helperText={errors.password2?.message} />

					<TextFiled
						fullWidth
						size='small'
						type='password'
						label='Re-password'
						{...register('password2', {
							required: { value: true, message: 'Please enter your re-password' }
						})}
						error={Boolean(errors.password2)}
						helperText={errors.password2?.message}
					/>

					<TextFiled
						fullWidth
						size='small'
						label='Phone Number'
						{...register('phone', {
							required: { value: true, message: 'Please enter your phone number' }
						})}
						error={Boolean(errors.phone)}
						helperText={errors.phone?.message}
					/>

					<TextFiled
						fullWidth
						size='small'
						label='City'
						{...register('city', {
							required: { value: true, message: 'Please enter your city' }
						})}
						error={Boolean(errors.city)}
						helperText={errors.city?.message}
					/>

					<TextFiled
						fullWidth
						size='small'
						label='Zip Code'
						{...register('zip', {
							required: { value: true, message: 'Please enter your zip code' }
						})}
						error={Boolean(errors.zip)}
						helperText={errors.zip?.message} />

					<Box mt='20px' width='50%' mx='auto' display='flex' flexDirection='column'>
						<Button size='large' variant='contained' disabled={isLoading === LoadingStatus.LOADING} type='submit'> SIGN UP </Button>
						<NavLink to={routes.signIn}>
							<Button size='large' fullWidth> SIGN IN </Button>
						</NavLink>
					</Box>

				</Box>
			</form>
		</AuthorizationWrapper>
	);
};