import React from 'react';

// --- UI
import { Skeletons } from '../../../components';
import { Box, Table, TableHead, TableBody, TableCell, Typography, IconButton, TableCellProps, Tooltip, ClickAwayListener, Popover, Button } from '@mui/material';

// --- JS
import { useAppSelector, useAppDispatch } from '../../../store';
import { DeveloperApi } from '../../../store/Developer/Developer.services';
import { fetchAccessKeys } from '../../../store/Developer/Developer.actions';

// --- ASSETS
import LockIcon from '@mui/icons-material/Lock';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// --- TS
import { LoadingStatus } from '../../../shared/status';
import { selectAccessKeys, selectIsLoading } from '../../../store/Developer/Developer.selectors';





export const KeysList: React.FC = () => {

	const isLoading = useAppSelector(selectIsLoading);
	const accessKeys = useAppSelector(selectAccessKeys);



	if (isLoading === LoadingStatus.LOADING) {
		return (
			<Skeletons
				count={6}
				boxProps={{ mt: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
				skeletonProps={{ variant: 'rectangular', width: '100%', height: '50px' }} />
		);
	}

	return (
		<Table size='small'>
			<TableHead>
				<TableCell sx={{ fontWeight: 700 }}> Name </TableCell>
				<TableCell sx={{ fontWeight: 700 }}> Developer ID </TableCell>
				<TableCell sx={{ fontWeight: 700 }}> Key ID </TableCell>
				<TableCellWithIcon sx={{ fontWeight: 700 }} text='Signing Secret'> <ErrorOutlineIcon fontSize='small' /> </TableCellWithIcon>
				<TableCell />
			</TableHead>


			{accessKeys.map((key) => (
				<TableBody key={key.id}>

					<TableCell> {key.name} </TableCell>

					<TableCellWithIcon isCopied text={key.developerId}> <ContentCopyIcon fontSize='small' /> </TableCellWithIcon>

					<TableCellWithIcon isCopied text={key.keyId}> <ContentCopyIcon fontSize='small' /> </TableCellWithIcon>

					<TableCellWithIcon text={'***************'}> <LockIcon fontSize='small' /> </TableCellWithIcon>

					<ShowMoreIcon id={key.id} />

				</TableBody>
			))}


		</Table>
	);
};


interface TableCellWithIconProps extends TableCellProps {
	text?: string;
	isCopied?: boolean;
	children: React.ReactNode;
}

const TableCellWithIcon: React.FC<TableCellWithIconProps> = ({ children, text, isCopied = false, ...tableCellProps }) => {

	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = (): void => {
		setOpen(false);
	};

	const handleTooltipOpen = (): void => {
		setOpen(true);
	};

	const onClipBoard = (): void => {
		if (text && isCopied) {
			navigator.clipboard.writeText(text);
			handleTooltipOpen();
		}
	};


	return (
		<TableCell {...tableCellProps}>
			<Box display='flex' alignItems='center' gap='5px'>
				<IconButton onClick={onClipBoard}>
					{isCopied
						? <ClickAwayListener onClickAway={handleTooltipClose}>
							<Tooltip
								open={open}
								title='Copied'
								disableFocusListener
								disableHoverListener
								disableTouchListener
								onClose={handleTooltipClose}
								PopperProps={{ disablePortal: true }}>
								<Box> {children} </Box>
							</Tooltip>
						</ClickAwayListener>

						: children}

				</IconButton>
				{text && <Typography> {text} </Typography>}
			</Box>
		</TableCell>
	);
};


interface ShowMoreIconProps {
	id: number;
}

const ShowMoreIcon: React.FC<ShowMoreIconProps> = ({ id }) => {

	const dispatch = useAppDispatch();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const onOpenPopover = (e: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(e.currentTarget);
	};

	const onClosePopover = (): void => {
		setAnchorEl(null);
	};

	const onDeleteAccessKey = async (id: number): Promise<void> => {
		try {
			await DeveloperApi.deleteAccessKey(id);
			dispatch(fetchAccessKeys());
		} catch(e) {
			console.error('[onDeleteAccessKey]');
		}
	};

	const open = Boolean(anchorEl);
	const popoverId = open ? 'simple-popover' : undefined;

	return (
		<TableCell> 
			<IconButton aria-describedby={popoverId} onClick={onOpenPopover}> 
				<MoreVertIcon fontSize='small' /> 
			</IconButton>
			<Popover 
				id={popoverId}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)} 
				onClose={onClosePopover}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>

				<Box width='120px' py='8px'>
					<Button 
						fullWidth 
						color='error'
						onClick={(): Promise<void> => onDeleteAccessKey(id)}> 
						Revoke 
					</Button>
				</Box>

			</Popover>
		</TableCell>
	);
};