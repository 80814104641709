import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// --- JS
import { NavLink } from 'react-router-dom';
// --- ASSETS
import InstagramIcon from '../assets/instagram.png';
import FacebookIcon from '../assets/facebook.png';
import YoutubeIcon from '../assets/youtube.png';

export const Footer: React.FC = () => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			gap='42px'

			p='32px 12px'
			bgcolor='black'>

			<Box display='flex' alignItems='center' gap='32px'>
				<a href='/'> <img src={InstagramIcon} alt='instagram' /> </a>
				<a href='/'> <img src={FacebookIcon} alt='facebook' /> </a>
				<a href='/'> <img src={YoutubeIcon} alt='youtube' /> </a>
			</Box>

			<Box display='flex' gap='48px'>
				<Typography variant='subtitle1' color='white'> Terms of Use </Typography>
				<Typography variant='subtitle1' color='white'> Privacy Policy </Typography>
			</Box>

			<Typography variant='body1' color='grey'>
				Copyright 2022 ASEND. All Rights Reserved.
			</Typography>

		</Box>
	);
};