import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { KeysList, CreateAccessKey } from './components';
// --- JS
import { useAppDispatch } from '../../store';
import { fetchAccessKeys } from '../../store/Developer/Developer.actions';

export const DeveloperPage: React.FC = () => {

	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(fetchAccessKeys());
	}, [dispatch]);

	return (
		<Box>

			<Typography variant='h3' fontWeight='700'> Credentials </Typography>

			<Typography> Access keys enable you to authenticate with ASEND API. </Typography>

			<CreateAccessKey />

			<KeysList />

		</Box>
	);
};