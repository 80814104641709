import React from 'react';
import { Box, IconButton, Typography, ClickAwayListener, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface ItemWithCopyIconProps {
	label?: string;
	value?: string;
}

export const ItemWithCopyIcon: React.FC<ItemWithCopyIconProps> = (props) => {

	const { label, value } = props;

	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = (): void => {
		setOpen(false);
	};

	const handleTooltipOpen = (): void => {
		setOpen(true);
	};

	const onClipBoard = (): void => {
		if (value) {
			navigator.clipboard.writeText(value);
			handleTooltipOpen();
		}
	};

	return (
		<Box
			p='8px 12px'
			display='flex'
			alignItems='center'
			justifyContent='space-between'
			sx={{
				cursor: 'pointer',
				transition: 'all .3s',
				'&:hover': {
					background: '#e8fcfb',
				}
			}}>

			<Box ml='5px'>
				<Typography fontWeight='500'> {label} </Typography>
				<Typography fontWeight='400' variant='subtitle2'> {value} </Typography>
			</Box>
			<IconButton size='small' onClick={onClipBoard}>
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<Tooltip
						open={open}
						title='Copied'
						disableFocusListener
						disableHoverListener
						disableTouchListener
						onClose={handleTooltipClose}
						PopperProps={{ disablePortal: true }}>

						<ContentCopyIcon fontSize='small' />

					</Tooltip>
				</ClickAwayListener>
			</IconButton>

		</Box>
	);
};