import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Box from '@mui/material/Box';


export interface LayoutProps {
	children?: React.ReactNode;
	showFooter?: boolean;
}

export const Layout: React.FC<LayoutProps> = (props) => {

	const { children, showFooter } = props;

	return (
		<Box position='relative'>
			<Box
				width='100%'
				maxWidth='1240px'
				margin='0 auto'
				minHeight='100vh'>
				<Header />
				<Box pt='50px'>
					{children}
				</Box>
			</Box>
			
			{showFooter &&  <Footer />}
			
		</Box>
	);
};


export const withLayout = <T extends Record<string, unknown>>(Component: React.FC<T>) => {
	return function withLayoutComponent(props: T): JSX.Element {
		return (
			<Layout showFooter>
				<Component {...props} />
			</Layout>
		);
	};
};

export const withLayoutOnlyHeader = <T extends Record<string, unknown>>(Component: React.FC<T>) => {
	return function withLayoutComponent(props: T): JSX.Element {
		return (
			<Layout>
				<Component {...props} />
			</Layout>
		);
	};
};
