import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export const WelcomePage: React.FC = () => {


	return (
		<Box
			mt='72px'
			display='flex'
			justifyContent='center'>

			<Typography
				variant='h2'
				fontWeight='700'
				textAlign='center'
				fontSize='3.6em'
				lineHeight='1.5em'>
				Next Generation <br />
				Food Delivery System <br />
				for your Business
			</Typography>

		</Box>
	);
};