import axios from 'axios';

export const instance = axios.create({
	baseURL: 'https://api.asend.ai/integration/v1/'
});

instance.interceptors.request.use((request: any) => {
	const token = localStorage.getItem('token');
	if (token) {
		request.headers["Authorization"] = `Bearer ${token}`;
	}
	request.headers["Content-Type"] = "application/json";
	return request;
});