import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IAppInitialState } from './types';

const fetchInitialized: CaseReducer<IAppInitialState, PayloadAction> = (_state, _action) => {};

const setInitialized: CaseReducer<IAppInitialState, PayloadAction<IAppInitialState['initialized']>> = (_state, _action) => {
	_state.initialized = _action.payload;
};

export default { fetchInitialized, setInitialized };

