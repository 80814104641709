import rootSaga from './rootSaga';
import { rootReducer } from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleWare from 'redux-saga';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

const sagaMiddleWare = createSagaMiddleWare();

const middlewares = [ sagaMiddleWare ];

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

sagaMiddleWare.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = (): any => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store };

