import { put, takeLatest } from '@redux-saga/core/effects';
import { loggerError, LoggerType } from '../../shared/logger';
import { setAuthorizationStatus, fetchLogout, fetchCheckAuthorization } from './Authorization.actions';

export function* fetchCheckAuthorizationRequest(): Generator {
	try {
		const token = localStorage.getItem('token');
		if (token) {
			yield put(setAuthorizationStatus(true));
		}
	} catch (e) {
		loggerError({ e, type: LoggerType.NETWORK });
	}
}

export function* fetchLogoutRequest(): Generator {
	try {
		localStorage.removeItem('token');
		yield put(setAuthorizationStatus(false));
	} catch (e) {
		loggerError({ e, type: LoggerType.NETWORK });
	}
}

export function* saga(): Generator {
	yield takeLatest(fetchLogout, fetchLogoutRequest);
	yield takeLatest(fetchCheckAuthorization, fetchCheckAuthorizationRequest);
}