// --- JS
import { instance } from '../../services';
// --- TS
import { ICreateAccessKeyForm } from '../../pages/DeveloperPage/types';
import { IAccessKey, ICreateAccessKeyResponse } from './types';

export const DeveloperApi = {

	async getAccessKeys(): Promise<IAccessKey[]> {
		const { data } = await instance.get('jwt-keys');
		return data;
	},

	async createAccessKey(params: ICreateAccessKeyForm): Promise<ICreateAccessKeyResponse> {
		const { data } = await instance.post('jwt-issue', params);
		return data;
	},

	async deleteAccessKey(id: number): Promise<void> {
		const { data } = await instance.delete(`jwt-keys/${id}`);
		return data;
	}
};

