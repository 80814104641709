export enum LoggerType {
	UI = 'UI',
	OTHER = 'OTHER',
	NETWORK = 'NETWORK',
}

interface ILoggerError {
	e: any,
	type?: LoggerType,
}

export const loggerError = (params: ILoggerError): void => {
	const { e, type } = params;
	
	let errorText = 'Error occurred: ';

	if (type) {
		errorText = `[${type}]: ${errorText}`;
	}

	console.log(`${errorText}`, e);
};