import React from 'react';
import { Box, BoxProps, Skeleton, SkeletonProps } from '@mui/material';


interface SkeletonsProps {
	count?: number;
	boxProps?: BoxProps;
	skeletonProps?: SkeletonProps;
}

export const Skeletons: React.FC<SkeletonsProps> = (props) => {
	const { count = 1, boxProps, skeletonProps } = props;
	return (
		<Box {...boxProps}>
			{Array(count).fill(1).map((_sk, idx) => (
				<Skeleton key={idx} {...skeletonProps} />
			))}
		</Box>
	);
};