import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { customTheme } from './styles/mui';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';

import reportWebVitals from './reportWebVitals';
import './styles/global.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const forceSlashAfterHash = (): void => {
    let _hash = window.location.hash;
    if (_hash[1] && _hash[1] != '/') {
        window.location.href = window.location.origin + window.location.pathname + window.location.search + "#/" + _hash.slice(1);
    }
};

forceSlashAfterHash();

window.addEventListener('hashchange', forceSlashAfterHash);

root.render(
	<HashRouter>
		<ThemeProvider theme={customTheme}>
			<Provider store={store}>
				<App />
			</Provider>
		</ThemeProvider>
	</HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
