import { loggerError, LoggerType } from "../../shared/logger";
import { setInitialized, fetchInitialized } from "./App.actions";
import { put, call, takeLatest } from "@redux-saga/core/effects";
import { fetchCheckAuthorizationRequest } from "../Authorization/Authorization.sagas";

export function* fetchInitializedRequest(): Generator {
	try {
		yield call(fetchCheckAuthorizationRequest);
		yield put(setInitialized(true));
	} catch (e) {
		loggerError({ e, type: LoggerType.NETWORK });
	}
}

export function* saga(): Generator {
	yield takeLatest(fetchInitialized, fetchInitializedRequest);
}