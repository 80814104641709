import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// --- JS
import { routes } from '../shared/routes';
import { useAppSelector } from '../store';
import { Navigate } from 'react-router-dom';
import { selectAuthorizationStatus } from '../store/Authorization/Authorization.selectors';


interface AuthorizationWrapperProps {
	title?: string;
	children?: React.ReactNode;
}

export const AuthorizationWrapper: React.FC<AuthorizationWrapperProps> = (props) => {

	const { title, children } = props;

	const isAuth = useAppSelector(selectAuthorizationStatus);

	if (isAuth) {
		return <Navigate to={routes.welcome} />;
	}


	return (
		<Box
			width='100%'
			minHeight='100vh'
			display='flex'
			alignItems='center'
			justifyContent='center'>

			<Box
				p='32px'
				width='100%'
				maxWidth='450px'
				borderRadius='8px'
				boxShadow={7}>

				{title &&
					<Typography
						variant='h4'
						fontWeight='700'
						textAlign='center'>
						{title}
					</Typography>}

				<Box width='100%'>
					{children}
				</Box>

			</Box>
		</Box>
	);
};