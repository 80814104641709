import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextFiled from '@mui/material/TextField';
import { AuthorizationWrapper } from '../../components';
// --- JS
import { useAppDispatch } from '../../store';
import { routes } from '../../shared/routes';
import { isValidEmail } from '../../shared/regex';
import { NavLink } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { loggerError, LoggerType } from '../../shared/logger';
import { AuthorizationApi } from '../../store/Authorization/Authorization.services';
import { setAuthorizationStatus } from '../../store/Authorization/Authorization.actions';
// --- TYPES
import { ISignInForm } from './types';
import { LoadingStatus } from '../../shared/status';


export const SignInPage: React.FC = () => {

	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = React.useState(LoadingStatus.NEVER);

	const { register, handleSubmit, formState } = useForm<ISignInForm>();

	const { errors } = formState;

	const onSignInRequest: SubmitHandler<ISignInForm> = async (form) => {
		try {
			setIsLoading(LoadingStatus.LOADING);
			const { id_token } = await AuthorizationApi.signIn(form);
			localStorage.setItem('token', id_token);
			dispatch(setAuthorizationStatus(true));
			setIsLoading(LoadingStatus.LOADED);
		} catch (e: any) {
			setIsLoading(LoadingStatus.ERROR);
			loggerError({ e, type: LoggerType.NETWORK });
		}
	};

	return (
		<AuthorizationWrapper title='ASEND'>
			<form onSubmit={handleSubmit(onSignInRequest)}>

				{isLoading === LoadingStatus.ERROR
					&& <Box mb='15px'>
						<Alert severity='error'>  Wrong email or password  </Alert>
					</Box>}

				<Box display='flex' flexDirection='column' gap='20px'>
					<TextFiled
						fullWidth
						size='small'
						label='Email'
						{...register('email', {
							required: { value: true, message: 'Please enter your email' },
							pattern: { value: isValidEmail, message: 'Please use a valid email' }
						})}
						error={Boolean(errors.email)}
						helperText={errors.email?.message} />

					<TextFiled
						fullWidth
						size='small'
						type='password'
						label='Password'
						{...register('password', {
							required: { value: true, message: 'Please enter your password' }
						})}
						error={Boolean(errors.email)}
						helperText={errors.password?.message} />
				</Box>

				<Box mt='20px' width='50%' mx='auto' display='flex' flexDirection='column'>
					<Button size='large' variant='contained' disabled={isLoading === LoadingStatus.LOADING} type='submit'> SIGN IN </Button>
					<NavLink to={routes.signUp}>
						<Button size='large' fullWidth> SIGN UP </Button>
					</NavLink>
				</Box>

			</form>
		</AuthorizationWrapper>
	);
};