import React from 'react';
// --- UI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import { ItemWithCopyIcon } from './ItemWithCopyIcon';
// --- ASSETS
import CloseIcon from '@mui/icons-material/Close';
// --- JS
import { useAppDispatch } from '../../../store';
import { useForm, SubmitHandler } from 'react-hook-form';
import { loggerError, LoggerType } from '../../../shared/logger';
import { DeveloperApi } from '../../../store/Developer/Developer.services';
import { fetchAccessKeys } from '../../../store/Developer/Developer.actions';
// --- TYPES
import { ICreateAccessKeyForm } from '../types';
import { LoadingStatus } from '../../../shared/status';
import { ICreateAccessKeyResponse } from '../../../store/Developer/types';



export const CreateAccessKey: React.FC = () => {

	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = React.useState(LoadingStatus.NEVER);
	const [accessKey, setAccessKey] = React.useState<ICreateAccessKeyResponse | null>(null);

	const onCreateAccessKey: SubmitHandler<ICreateAccessKeyForm> = async (form) => {
		try {
			setIsLoading(LoadingStatus.LOADING);
			const data = await DeveloperApi.createAccessKey(form);
			dispatch(fetchAccessKeys());
			setAccessKey(data);
			setIsLoading(LoadingStatus.LOADED);
		} catch (e) {
			loggerError({ e, type: LoggerType.NETWORK });
			setIsLoading(LoadingStatus.ERROR);
		}
	};

	const [open, setOpen] = React.useState(false);

	const onOpen = (): void => setOpen(true);

	const onClose = (): void => {
		setOpen(false);
		setIsLoading(LoadingStatus.NEVER);
	};

	return (
		<React.Fragment>

			<Box mt='50px' mb='30px' display='flex' alignItems='center' justifyContent='space-between'>
				<Typography variant='h6' fontWeight='700'> Access Keys </Typography>
				<Button
					size='large'
					variant='contained'
					onClick={onOpen}
					sx={{ borderRadius: '28px', fontWeight: 500 }}>
					Create Access Key
				</Button>
			</Box>

			<Modal open={open} onClose={onClose}>
				<Box>
					{isLoading === LoadingStatus.LOADED
						? <SuccessAccessKey accessKey={accessKey} onClose={onClose} />
						: <CreateAccessKeyForm onClose={onClose} onCreateAccessKey={onCreateAccessKey} />}
				</Box>
			</Modal>

		</React.Fragment>
	);
};



interface CreateAccessKeyFormProps {
	onClose: () => void;
	onCreateAccessKey: (form: ICreateAccessKeyForm) => Promise<void>;
}

export const CreateAccessKeyForm: React.FC<CreateAccessKeyFormProps> = (props) => {

	const { onClose, onCreateAccessKey } = props;

	const { register, handleSubmit, formState } = useForm<ICreateAccessKeyForm>();

	const { errors, isSubmitting } = formState;

	return (
		<Box sx={style}>

			<Typography fontWeight='700' variant='h6'> Create a new access key </Typography>

			<form onSubmit={handleSubmit(onCreateAccessKey)}>

				<Typography mt='20px' mb='10px' color='grey'> Name your access keys easier reference </Typography>

				<TextField
					fullWidth
					size='small'
					label='Name'
					{...register('name', {
						required: { value: true, message: 'Please enter your name' },
					})}
					error={Boolean(errors.name)}
					helperText={errors.name?.message} />

				<Box mt='30px' display='flex' alignItems='center' gap='10px'>
					<Button type='submit' size='large' variant='contained' disabled={isSubmitting}> SAVE </Button>
					<Button onClick={onClose} size='large' disabled={isSubmitting}> CANCEL </Button>
				</Box>

			</form>
		</Box>
	);
};



interface SuccessAccessKeyProps {
	accessKey?: ICreateAccessKeyResponse | null;
	onClose: () => void;
}

export const SuccessAccessKey: React.FC<SuccessAccessKeyProps> = (props) => {

	const { accessKey, onClose } = props;

	return (
		<Box sx={style}>
			<Box display='flex' alignItems='center' justifyContent='space-between' mb='15px'>
				<Typography fontWeight='700' variant='h6'> A new access key was created </Typography>
				<IconButton size='small' onClick={onClose}> <CloseIcon /> </IconButton>
			</Box>
			<Alert severity="warning" sx={{ mb: '20px' }}>
				<AlertTitle> Save your Signing Secret! </AlertTitle>
				Once the key is created, copy it and paste it into your code.
				For security reasons, you won’t be able to access the Signing Secret later.
			</Alert>

			<ItemWithCopyIcon label='Key ID' value={accessKey?.keyId} />
			<ItemWithCopyIcon label='Developer ID' value={accessKey?.developerId} />
			<ItemWithCopyIcon label='Signing Secret' value={accessKey?.signingSecret} />
		</Box>
	);
};




const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	maxWidth: '600px',
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: '8px',
	p: 4,
};